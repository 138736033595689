<template>
    <div class="zt-page-content">
        <!-- <div class="zt-block" style="margin-bottom:0">
            <div class="tab_menu flex">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    {{item.name}}
                </div>
            </div>
        </div> -->

        <Txgl :key="tabIndex" :price_type="5"></Txgl>
    </div>
</template>
<script>
    import Txgl from '@/components/txglCommon/Txgl.vue';
    export default {
        components: {
            Txgl
        },
        data() {
            return {
                tabList:[
                    {name:"自有款"},
                ],
                tabIndex:0,
            }
        },
        created() {
            if (this.$route.query.idx) {
                this.tabIndex = this.$route.query.idx;
            }
        },
        mounted() {},
        methods: {
            tab(index) { // 选项卡
                this.tabIndex = index;
                console.log(index);
            },
        }
    }
</script>
<style scoped>
    /*----选项卡----*/
    .tab_menu {
        height: 60px;
        line-height: 60px;
        background:#E6E6E6;
    }
    .tab_menu .menu_item{
        padding: 0 24px;
        height:60px;
        line-height: 60px;
        cursor: pointer;
        font-size: 18px;
        color: #4D4D4D;
        position: relative;
    }
    .tab_menu .menu_item.cur{
        background: #fff;
    }
    .tab_menu .menu_item.cur::before {
        content: '';
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 4px solid #24B1EB;
    }
</style>